<template>
  <div class="tw-flex tw-w-full tw-min-h-screen tw-flex-col">
<div class=" tw-flex tw-w-full tw-flex-col tw-px-4 md:tw-px-20 lg:tw-px-40 ">
   <booking-steps />
  <div class="back " @click="$router.push({name:'RoundTripSelectTrip'})"><v-icon>mdi-chevron-left</v-icon>Back</div>
  <h6 class="seats-header tw-pt-14 tw-pb-8">Seats</h6>
  <div class="tw-flex tw-w-full tw-flex-col lg:tw-flex-row">
    <div class="tw-flex tw-w-full lg:tw-w-1/2 tw-flex-col">
     <h6 class="header">Departure</h6>
      <div class="tw-flex tw-w-full tw-flex-row tw-items-center tw-py-8">
        <img class="company-logo" :src="departure.companyLogo" alt="logo"/>
        <v-col  class="tw-flex tw-flex-row tw-items-center">
          <h6 class="company-name tw-ml-5 text-capitalize">{{departure.companyName}}</h6>
        </v-col>
        <v-col  class="tw-flex  tw-flex-row tw-items-center">
          <h6 class="trip-time tw-ml-5 text-uppercase">{{departure.departureState.substring(0,3)}} - {{departure.destinationState.substring(0,3)}}</h6>
        </v-col>
        <v-col  class="tw-hidden md:tw-flex  tw-flex-row tw-items-center">
          <h6 class="trip-time tw-ml-5 text-uppercase">{{departure.takeOffTime}}</h6>
        </v-col>
        <v-col  class="tw-hidden md:tw-flex  tw-flex-row tw-items-center">
          <h6 class="trip-time tw-ml-5 text-uppercase">&#8358; {{departure.amount}}</h6>
        </v-col>
      </div>

      <div class="tw-flex tw-w-full tw-justify-center tw-py-5">
        <div class="tw-flex  tw-flex-col tw-w-11/12 md:tw-w-10/12 ">
          <div class="tw-flex md:tw-hidden tw-w-full tw-justify-between tw-py-5">
            <h6 class="trip-time text-uppercase ">{{departure.takeOffTime}}</h6>
            <h6 class="trip-time text-uppercase" style="color: #004aad; font-size: 20px">&#8358; {{departure.amount}}</h6>
          </div>
          <div class="tw-w-full tw-flex tw-items-center">
            <icons name="to-and-fro" color="#959595" class="tw-mr-5" />
            <div class="tw-w-11/12 tw-flex-col">
              <div class="tw-flex tw-w-full tw-justify-between tw-items-center">

                <h6 class="trip-time text-uppercase ">{{departure.takeOffTime}}
                  <span class="trip-time text-capitalize tw-pl-3">{{departure.departureState}} ({{departure.departureCity}} - {{departure.departureTerminalAddress}})</span>
                </h6>
                <h6 class="trip-time text-uppercase tw-hidden md:tw-flex" style="color: #004aad; font-size: 20px">&#8358; {{departure.amount}}</h6>
              </div>
              <div class="tw-flex tw-w-full tw-justify-between tw-items-center tw-mt-8">
                <h6 class="trip-time text-capitalize">5 hours (Approx) 2 stops</h6>

                <h6 class="trip-time text-capitalize tw-hidden md:tw-flex" >Available: 2</h6>
              </div>
              <div class="tw-flex tw-w-full tw-justify-between tw-items-center tw-mt-8">
                <h6 class="trip-time text-uppercase ">{{departure.takeOffTime}}
                  <span class="trip-time text-capitalize tw-pl-3">
                  {{departure.departureState}} ({{departure.departureCity}} - {{departure.departureTerminalAddress}})
                </span>
                </h6>
              </div>
            </div>

          </div>
          <h6 class="trip-time text-capitalize tw-flex md:tw-hidden tw-mt-5" >Available: 2</h6>
          <h6 class="tips tw-mt-10">Greener Line  .  Coaster Bus  .  Economy  . BUS 567 </h6>
          <vehicle-seats :trip="departure" />
        </div>
      </div>
    </div>
    <v-divider vertical v-if="$vuetify.breakpoint.mdAndUp" />
    <div class="tw-flex tw-w-full lg:tw-w-1/2 tw-flex-col tw-pl-5">
      <h6 class="header">Return</h6>
      <div class="tw-flex tw-w-full tw-flex-row tw-items-center tw-py-8">
        <img class="company-logo" :src="departure.companyLogo" alt="logo"/>
        <v-col  class="tw-flex tw-flex-row tw-items-center">
          <h6 class="company-name tw-ml-5 text-capitalize">{{departure.companyName}}</h6>
        </v-col>
        <v-col  class="tw-flex  tw-flex-row tw-items-center">
          <h6 class="trip-time tw-ml-5 text-uppercase">{{departure.departureState.substring(0,3)}} - {{departure.destinationState.substring(0,3)}}</h6>
        </v-col>
        <v-col  class="tw-hidden md:tw-flex  tw-flex-row tw-items-center">
          <h6 class="trip-time tw-ml-5 text-uppercase">{{departure.takeOffTime}}</h6>
        </v-col>
        <v-col  class="tw-hidden md:tw-flex  tw-flex-row tw-items-center">
          <h6 class="trip-time tw-ml-5 text-uppercase">&#8358; {{departure.amount}}</h6>
        </v-col>
      </div>
      <div class="tw-flex tw-w-full tw-justify-center tw-py-5">
        <div class="tw-flex  tw-flex-col tw-w-11/12 md:tw-w-10/12 ">
          <div class="tw-flex md:tw-hidden tw-w-full tw-justify-between tw-py-5">
            <h6 class="trip-time text-uppercase ">{{departure.takeOffTime}}</h6>
            <h6 class="trip-time text-uppercase" style="color: #004aad; font-size: 20px">&#8358; {{departure.amount}}</h6>
          </div>
          <div class="tw-w-full tw-flex tw-items-center">
            <icons name="to-and-fro" color="#959595" class="tw-mr-5" />
            <div class="tw-w-11/12 tw-flex-col">
              <div class="tw-flex tw-w-full tw-justify-between tw-items-center">

                <h6 class="trip-time text-uppercase ">{{departure.takeOffTime}}
                  <span class="trip-time text-capitalize tw-pl-3">{{departure.departureState}} ({{departure.departureCity}} - {{departure.departureTerminalAddress}})</span>
                </h6>
                <h6 class="trip-time text-uppercase tw-hidden md:tw-flex" style="color: #004aad; font-size: 20px">&#8358; {{departure.amount}}</h6>
              </div>
              <div class="tw-flex tw-w-full tw-justify-between tw-items-center tw-mt-8">
                <h6 class="trip-time text-capitalize">5 hours (Approx) 2 stops</h6>

                <h6 class="trip-time text-capitalize tw-hidden md:tw-flex" >Available: 2</h6>
              </div>
              <div class="tw-flex tw-w-full tw-justify-between tw-items-center tw-mt-8">
                <h6 class="trip-time text-uppercase ">{{departure.takeOffTime}}
                  <span class="trip-time text-capitalize tw-pl-3">
                  {{departure.departureState}} ({{departure.departureCity}} - {{departure.departureTerminalAddress}})
                </span>
                </h6>
              </div>
            </div>

          </div>
          <h6 class="trip-time text-capitalize tw-flex md:tw-hidden tw-mt-5" >Available: 2</h6>
          <h6 class="tips tw-mt-10">Greener Line  .  Coaster Bus  .  Economy  . BUS 567 </h6>
          <vehicle-seats :trip="departure" />
        </div>
      </div>
    </div>
  </div>
</div>
    <trip-details />
  </div>
</template>

<script>
import BookingSteps from "@/components/reuseables/bookingAndHiring/BookingSteps";
import companyLogo from "@/assets/bustriptwo.png";
import Icons from "@/components/reuseables/Icons";
import VehicleSeats from "@/components/reuseables/bookingAndHiring/VehicleSeats";
import TripDetails from "@/components/reuseables/bookingAndHiring/TripDetails";
export default {
  name: "SelectSeatsForReturnTrip",
  components: {TripDetails, VehicleSeats, Icons, BookingSteps},
  data(){
    return{
      departure:{
        id : 1,
        companyLogo : companyLogo,
        companyName: "Greener Line",
        departureState: "Lagos",
        departureCity: "Yaba",
        departureTerminalAddress: "Ognenevo",
        destinationState: "Delta",
        destinationCity: "Effurun",
        destinationTerminalAddress: "44, Effurun roadabout, Effurun",
        takeOffTime : "10:30AM",
        amount : "9,900",
        seats:[
          {
            seatNumber: 1,
            seatstatus: "AVAILABLE"
          },
          {
            seatNumber: 2,
            seatstatus: "AVAILABLE"
          },
          {
            seatNumber: 3,
            seatstatus: "AVAILABLE"
          },
          {
            seatNumber: 4,
            seatstatus: "AVAILABLE"
          },
          {
            seatNumber: 5,
            seatstatus: "AVAILABLE"
          },
          {
            seatNumber: 6,
            seatstatus: "AVAILABLE"
          },
          {
            seatNumber: 7,
            seatstatus: "AVAILABLE"
          },
          {
            seatNumber: 8,
            seatstatus: "AVAILABLE"
          },
          {
            seatNumber: 9,
            seatstatus: "AVAILABLE"
          },
          {
            seatNumber: 10,
            seatstatus: "AVAILABLE"
          },
          {
            seatNumber: 11,
            seatstatus: "AVAILABLE"
          },
          {
            seatNumber: 12,
            seatstatus: "AVAILABLE"
          },
          {
            seatNumber: 13,
            seatstatus: "AVAILABLE"
          },
          {
            seatNumber: 14,
            seatstatus: "AVAILABLE"
          },
          {
            seatNumber: 15,
            seatStatus: "AVAILABLE"
          },
          {
            seatNumber: 16,
            seatstatus: "AVAILABLE"
          },
          {
            seatNumber: 17,
            seatstatus: "AVAILABLE"
          },
          {
            seatNumber: 18,
            seatstatus: "AVAILABLE"
          },
          {
            seatNumber: 19,
            seatstatus: "AVAILABLE"
          },
          {
            seatNumber: 20,
            seatstatus: "AVAILABLE"
          },
          {
            seatNumber: 21,
            seatstatus: "AVAILABLE"
          },
          {
            seatNumber: 22,
            seatstatus: "AVAILABLE"
          },
          {
            seatNumber: 23,
            seatstatus: "AVAILABLE"
          },
          {
            seatNumber: 24,
            seatstatus: "AVAILABLE"
          },

        ]
      },
      returnTrip:{
        id : 1,
        companyLogo : companyLogo,
        companyName: "Greener Line",
        departureState: "Lagos",
        departureCity: "Yaba",
        departureTerminalAddress: "Ognenevo",
        destinationState: "Delta",
        destinationCity: "Effurun",
        destinationTerminalAddress: "44, Effurun roadabout, Effurun",
        takeOffTime : "10:30AM",
        amount : "9,900",
        seats:[
          {
            seatNumber: 1,
            seatstatus: "AVAILABLE"
          },
          {
            seatNumber: 2,
            seatstatus: "AVAILABLE"
          },
          {
            seatNumber: 3,
            seatstatus: "AVAILABLE"
          },
          {
            seatNumber: 4,
            seatstatus: "AVAILABLE"
          },
          {
            seatNumber: 5,
            seatstatus: "AVAILABLE"
          },
          {
            seatNumber: 6,
            seatstatus: "AVAILABLE"
          },
          {
            seatNumber: 7,
            seatstatus: "AVAILABLE"
          },
          {
            seatNumber: 8,
            seatstatus: "AVAILABLE"
          },
          {
            seatNumber: 9,
            seatstatus: "AVAILABLE"
          },
          {
            seatNumber: 10,
            seatstatus: "AVAILABLE"
          },
          {
            seatNumber: 11,
            seatstatus: "AVAILABLE"
          },
          {
            seatNumber: 12,
            seatstatus: "AVAILABLE"
          },
          {
            seatNumber: 13,
            seatstatus: "AVAILABLE"
          },
          {
            seatNumber: 14,
            seatstatus: "AVAILABLE"
          },
          {
            seatNumber: 15,
            seatStatus: "AVAILABLE"
          },
          {
            seatNumber: 16,
            seatstatus: "AVAILABLE"
          },
          {
            seatNumber: 17,
            seatstatus: "AVAILABLE"
          },
          {
            seatNumber: 18,
            seatstatus: "AVAILABLE"
          },
          {
            seatNumber: 19,
            seatstatus: "AVAILABLE"
          },
          {
            seatNumber: 20,
            seatstatus: "AVAILABLE"
          },
          {
            seatNumber: 21,
            seatstatus: "AVAILABLE"
          },
          {
            seatNumber: 22,
            seatstatus: "AVAILABLE"
          },
          {
            seatNumber: 23,
            seatstatus: "AVAILABLE"
          },
          {
            seatNumber: 24,
            seatstatus: "AVAILABLE"
          },

        ]
      },
    }
  }
}
</script>

<style scoped lang="scss">
.seats-header{
  font-family: 'Inter',sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: #004AAD;
}

.header{
  font-family: 'Inter',sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: #004AAD;
}

.company-logo{
  width: 48px;
  height: 48px;
  border-radius: 50%;
}

.company-name{
  font-family: 'Inter',sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #4F4F4F;
  flex-wrap: nowrap;
}

.trip-time{
  font-family: 'Inter',sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #4F4F4F;
}

.tips{
  font-family: 'Inter',sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #878787;
}

.back{
  display: flex;
  align-items: center;
  font-family: 'Inter',sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #4F4F4F;
  width: max-content;
  text-transform: none;
  cursor: pointer;
}
</style>