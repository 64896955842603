<template>
  <div class="tw-flex tw-w-full tw-flex-col">
  <div class="card">
    <div class="card-content tw-w-7/12 md:tw-w-9/12 tw-p-1 md:tw-p-8 tw-flex tw-flex-row ">
      <div class="tw-w-4/12  tw-flex-col tw-flex tw-items-center tw-justify-center lg:tw-justify-start"
           :style="$vuetify.breakpoint.mdAndUp ? 'border-right: 1px solid rgba(79, 79, 79, 0.27)' :''">
        <div class="tw-w-full tw-flex tw-flex-col lg:tw-flex-row  tw-items-start lg:tw-items-center">
          <img class="logo" src="@/assets/bustriptwo.png" alt="logo" />
          <div class="tw-flex tw-flex-col lg:tw-pl-5">
            <h6 class="content-big tw-mt-3 lg:tw-mt-0">LAG - WAR</h6>
            <h6 class="content-time tw-flex tw-flex-row tw-mt-3 lg:tw-mt-10">7:30AM &nbsp;&nbsp;&nbsp; &nbsp;
              <span :class="[$vuetify.breakpoint.mdAndUp ? 'tw-flex': 'tw-hidden']">9:00PM</span></h6>
          </div>
          <h6 class="content-amount tw-ml-5">&#8358;12,500</h6>
        </div>
        <div class="tw-w-full tw-hidden lg:tw-flex tw-items-center tw-mt-5 tw-ml-20">
          <h6 class="content-tip-small-text">Greener Line . coaster bus. economy. bus xyz </h6>
        </div>
      </div>

      <div class=" tw-w-4/12  tw-flex-col tw-flex tw-items-center tw-justify-center lg:tw-justify-start lg:tw-pl-8"
           :style="$vuetify.breakpoint.mdAndUp ? 'border-right: 1px solid rgba(79, 79, 79, 0.27)' :''" >
        <div class="tw-w-full tw-flex tw-flex-col lg:tw-flex-row  tw-items-start lg:tw-items-center">
          <img class="logo" src="@/assets/bustriptwo.png" alt="logo" />
          <div class="tw-flex tw-flex-col lg:tw-pl-5">
            <h6 class="content-big tw-mt-3 lg:tw-mt-0">LAG - WAR</h6>
            <h6 class="content-time tw-flex tw-flex-row tw-mt-3 lg:tw-mt-10">7:30AM &nbsp;&nbsp;&nbsp; &nbsp;
             <span :class="[$vuetify.breakpoint.mdAndUp ? 'tw-flex': 'tw-hidden']">9:00PM</span></h6>
          </div>
          <h6  class="content-amount tw-ml-5">&#8358;12,500</h6>

        </div>
        <div class="tw-w-full tw-hidden lg:tw-flex tw-items-center tw-mt-5 tw-ml-20">
          <h6 class="content-tip-small-text">Greener Line . coaster bus. economy. bus xyz </h6>
        </div>
      </div>

<!--      total cost and seats section-->
      <div class="tw-w-4/12  tw-flex-col tw-items-end lg:tw-items-start  lg:tw-pl-8 tw-flex  tw-justify-center ">
        <div v-if="$route.name === 'SelectTrip'" class="tw-flex tw-flex-col tw-w-full">
          <h6 class="content-total-cost-header">Total Cost</h6>
          <h6 class="content-total-cost-amount tw-my-4">&#8358;25,500</h6>
          <h6 class="content-trip-detail tw-flex tw-flex-row tw-items-center">Trip Detail
            <span :class="[$vuetify.breakpoint.mdAndUp ? 'tw-flex': 'tw-hidden']"><v-icon>mdi-chevron-down</v-icon></span>
          </h6>
        </div>
        <div v-else class="tw-flex tw-flex-col tw-w-full">
           <h6 class="seats">Departure Seat (s)</h6>
           <h6 class="seats tw-flex tw-flex-row tw-flex-wrap"> <span v-for="(seat,index) in seats" :key="index">{{seat.seatNumber}},</span></h6>
          <h6 class="seats tw-mt-1">Return Seat (s)</h6>
          <h6 class="seats tw-flex tw-flex-row tw-flex-wrap"> <span v-for="(seat,index) in seats" :key="index">{{seat.seatNumber}},</span></h6>
        </div>
      </div>
    </div>
    <div class="tw-flex tw-w-4/12 md:tw-w-2/12 tw-items-center ">
      <div v-if="$route.name === 'SelectTrip'" class="tw-flex tw-w-full custom-wrap">
      <v-btn text class="book-btn" @click="$router.push({name: 'RoundTripSelectSeats'})">Book Now</v-btn>
      <v-btn text class="schedule-btn">Schedule Trip</v-btn>
      </div>
      <div v-else class="tw-w-full">
        <v-btn text class="continue-btn"  @click="routeNext">Continue</v-btn>
      </div>
    </div>
  </div>
    <div class="tw-w-full tw-flex tw-flex-row lg:tw-py-20  tw-px-5 md:tw-px-24 lg:tw-px-48">
      <div class="tw-flex tw-w-1/2 tw-flex-col tw-pr-40 dropdown-div" style="border-right: 1px solid rgba(79, 79, 79, 0.27) ">
        <div class="tw-flex tw-items-center tw-justify-between ">
          <div class="tw-w-2/12">
          <img class="logo " src="@/assets/bustriptwo.png" alt="logo" />
          </div>
          <h6 class="company-name md:tw-w-10/12 lg:tw-w-3/12 ">Greener Line</h6>
          <h6 class="content-big tw-w-2/12" :style="$vuetify.breakpoint.mdAndDown ? 'display: none': ''">LAG - WAR</h6>
          <h6 class="trip-time tw-w-2/12" :style="$vuetify.breakpoint.mdAndDown ? 'display: none': ''">6:30AM</h6>
          <h6 class="trip-time tw-w-2/12" :style="$vuetify.breakpoint.mdAndDown ? 'display: none': ''" style="color: #004aad">&#8358;3,000</h6>
        </div>
        <div class="tw-flex tw-w-full tw-flex-row tw-items-start tw-mt-10 ">
          <div class="tw-flex tw-w-2/12 lg:tw-w-3/12 tw-justify-end">
            <icons name="to-and-fro" color="#959595" />
          </div>
          <div class="tw-flex lg:tw-w-5/12 tw-flex-col tw-pl-2 lg:tw-pl-5">
              <div class="tw-flex tw-w-full tw-items-center tw-flex-row tw-justify-between">
                <h6 class="trip-time">7:30AM</h6>
                <h6 class="content-big">LAGOS (Ikeja)</h6>
              </div>
            <div class="tw-flex tw-w-full tw-items-center tw-flex-row tw-justify-between tw-my-8">
                <h6 class="approx ">5 hours (Approx)  .  2 stops</h6>

            </div>
            <div class="tw-flex tw-w-full tw-items-center tw-flex-row tw-justify-between">
              <h6 class="trip-time">7:30AM</h6>
              <h6 class="content-big">LAGOS (Ikeja)</h6>
            </div>
          </div>
          <div class="tw-hidden lg:tw-flex tw-w-4/12 tw-items-end tw-flex-col  tw-justify-start">
            <h6 class="dropdown-amount" >&#8358;3,000</h6>
            <h6 class="approx tw-my-8">Available: 6</h6>
          </div>
        </div>
        <div class="tw-flex lg:tw-hidden tw-w-full tw-items-start tw-flex-col  tw-justify-start tw-pl-3 tw-mt-5">
          <h6 class="dropdown-amount" >&#8358;3,000</h6>
          <h6 class="approx tw-mt-5">Available: 6</h6>
        </div>
        <div class="tw-flex tw-w-full tw-justify-center lg:tw-mt-10">
          <h6 class="bottom-text">Greener Line  .  Coaster Bus  .  Economy  . BUS 567</h6>
        </div>

      </div>
      <div class="tw-flex tw-w-1/2 tw-flex-col lg:tw-pr-40  tw-pl-2 md:tw-pl-5 lg:tw-pl-10" >
        <div class="tw-flex tw-items-center tw-justify-between ">
          <div class="tw-w-2/12">
            <img class="logo " src="@/assets/bustriptwo.png" alt="logo" />
          </div>
          <h6 class="company-name md:tw-w-10/12 lg:tw-w-3/12 ">Greener Line</h6>
          <h6 class="content-big tw-w-2/12" :style="$vuetify.breakpoint.mdAndDown ? 'display: none': ''">LAG - WAR</h6>
          <h6 class="trip-time tw-w-2/12" :style="$vuetify.breakpoint.mdAndDown ? 'display: none': ''">6:30AM</h6>
          <h6 class="trip-time tw-w-2/12" :style="$vuetify.breakpoint.mdAndDown ? 'display: none': ''" style="color: #004aad">&#8358;3,000</h6>
        </div>
        <div class="tw-flex tw-w-full tw-flex-row tw-items-start tw-mt-10 ">
          <div class="tw-flex tw-w-2/12 lg:tw-w-3/12 tw-justify-end">
            <icons name="to-and-fro" color="#959595" />
          </div>
          <div class="tw-flex lg:tw-w-5/12 tw-flex-col tw-pl-2 lg:tw-pl-5">
            <div class="tw-flex tw-w-full tw-items-center tw-flex-row tw-justify-between">
              <h6 class="trip-time">7:30AM</h6>
              <h6 class="content-big">LAGOS (Ikeja)</h6>
            </div>
            <div class="tw-flex tw-w-full tw-items-center tw-flex-row tw-justify-between tw-my-8">
              <h6 class="approx ">5 hours (Approx)  .  2 stops</h6>

            </div>
            <div class="tw-flex tw-w-full tw-items-center tw-flex-row tw-justify-between">
              <h6 class="trip-time">7:30AM</h6>
              <h6 class="content-big">LAGOS (Ikeja)</h6>
            </div>
          </div>
          <div class="tw-hidden lg:tw-flex tw-w-4/12 tw-items-end tw-flex-col  tw-justify-start">
            <h6 class="dropdown-amount" >&#8358;3,000</h6>
            <h6 class="approx tw-my-8">Available: 6</h6>
          </div>
        </div>
        <div class="tw-flex lg:tw-hidden tw-w-full tw-items-start tw-flex-col  tw-justify-start tw-pl-3 tw-mt-5">
          <h6 class="dropdown-amount" >&#8358;3,000</h6>
          <h6 class="approx tw-mt-5">Available: 6</h6>
        </div>
        <div class="tw-flex tw-w-full tw-justify-center lg:tw-mt-10">
          <h6 class="bottom-text">Greener Line  .  Coaster Bus  .  Economy  . BUS 567</h6>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import Icons from "@/components/reuseables/Icons";
import {mapGetters} from "vuex";
export default {
  name: "TripDetails",
  components: {Icons},
  data(){
    return{

    }
  },
  computed:{
      ...mapGetters("returnTripDepartureSeatsSelection",["seats"])
  },
  methods:{
    routeNext(){
      if (this.$route.name === 'RoundTripInputTravellerDetails'){
        this.$router.push({name : 'RoundTripCheckout'})
      }
      else {
        this.$router.push({name : 'RoundTripInputTravellerDetails'})
      }
    }
  }
}
</script>

<style scoped lang="scss">
.card{
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-image: url("../../../assets/return_trip_bg.svg");
  height: 300px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  padding: 4rem 10rem;

  @media screen and (min-width: 1700px) {
   background-size: cover;
  }
  @media screen and (max-width: 1650px) {
    padding: 4rem 7rem;
  }
  @media screen and (max-width: 1400px) {
    padding: 2rem 7rem;
    height: 200px;
    background-size: cover;
  }
  @media screen and (max-width: 1320px) {
    padding: 2rem 5rem;
  }
  @media screen and (max-width: 1024px) {
    background-size: 100%;

  }
  @media screen and (max-width: 995px) {
    padding: 2rem 4rem;
  }
  @media screen and (max-width: 768px){
    height: 230px;
    background-size: cover;
    background-image: url("../../../assets/return_trip_tap_bg.svg");
  }
  @media screen and (max-width: 600px) {
    padding: 1rem;
    background-size: cover;
    background-image: url("../../../assets/return_trip_mobile_bg.svg");
  }
  @media screen and (max-width: 520px) {
    background-size: cover;
  }
}

.card-content{
  height: 147px;
  background: #F2F2F4;
  border: 1px dashed #000000;

  @media screen and (max-width: 1500px) {
    height: 130px;
  }
  @media screen and (max-width: 1230px) {
    height: 100px;
  }
  @media screen and (max-width: 875px) {
    height: 80px;
  }
  @media screen and (max-width: 768px) {
    height: 100px;
    width: 60%;
  }
  @media screen and (max-width: 370px) {
    height: 100px;
    width: 62%;
  }
}

.book-btn{
  display: flex;
  align-items: center;
  justify-content: center;
  width: max-content;
  height: 41px;
  background: #004AAD;
  border: 1px solid #004AAD;
  border-radius: 4px;
  font-family: 'Inter',sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #FAFAFA;
  text-transform: capitalize;
  @media screen and (max-width: 1700px) {
    width: 100%;
  }
}

.continue-btn{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 41px;
  background: #004AAD;
  border: 1px solid #004AAD;
  border-radius: 4px;
  font-family: 'Inter',sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #FAFAFA;
  text-transform: capitalize;

}
.schedule-btn{
  width: max-content;
  height: 41px;
  border: 1px solid #004AAD;
  border-radius: 4px;
  font-family: 'Inter',sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #004AAD;
  text-transform: capitalize;
  @media screen and (min-width: 1700px) {
    margin-left: 2rem;
  }
  @media screen and (max-width: 1700px) {
    width: 100%;
    margin-left: 0;
    margin-top: 2rem;
  }
}

.custom-wrap{
  @media screen and (max-width: 1700px) {
    flex-direction: column;
  }
  @media screen and (max-width: 768px){
    width: 100%;
  }
}

.logo{
  width: 60px;
  height: 60px;
  @media screen and (max-width: 1024px) {
    width: 20px;
    height: 20px;
  }
}

.content-big{
  font-family: 'Inter',sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #4F4F4F;

  @media screen and (max-width: 1024px) {
    font-size: 8px;
    line-height: 10px;
  }
}
.content-time{
  font-family: 'Inter',sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 8px;
  line-height: 10px;
  color: #4F4F4F;
  @media screen and (max-width: 1024px) {
    font-size: 6px;
    line-height: 7px;
  }
}

.content-amount{
  font-family: 'Inter',sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  color: #4F4F4F;

  @media screen and (max-width: 1024px) {
    display: none;
  }
}

.content-total-cost-header{
  font-family: 'Inter',sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #4F4F4F;

  @media screen and (max-width: 1024px) {
    font-size: 8px;
    line-height: 7px;
  }
}

.content-total-cost-amount{
  font-family: 'Inter',sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 29px;
  color: #004AAD;
  @media screen and (max-width: 1024px) {
    font-size: 12px;
    line-height: 10px;
  }
}

.content-trip-detail{
  font-family: 'Inter',sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  color: #4F4F4F;
  @media screen and (max-width: 1024px) {
   font-size: 8px;
    line-height: 7px;
  }
}

.content-tip-small-text{
  font-family: 'Inter',sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 8px;
  line-height: 10px;
  color: rgba(79, 79, 79, 0.5);

  @media screen and (max-width: 1024px) {
    display: none;
  }
}

.company-name{
  font-family: 'Inter',sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #4F4F4F;
}

.trip-time{
  font-family: 'Inter',sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #4F4F4F;

  @media screen and (max-width: 768px) {
    font-size: 8px;
    line-height: 9px;
  }
}

.approx{
  font-family: 'Inter',sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #4F4F4F;

  @media screen and (max-width: 768px) {
    font-size: 8px;
    line-height: 9px;
  }
}

.dropdown-amount{
  font-family: 'Inter',sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  color: #004AAD;
  @media screen and (max-width: 768px) {
    font-size: 14px;
    line-height: 16px;
  }
}

.bottom-text{
  font-family: 'Inter',sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #878787;
  @media screen and (max-width: 768px) {
    font-size: 8px;
    line-height: 15px;
    padding-left: 0.8rem;
    padding-top: 1rem;
  }
}

.dropdown-div{
  @media screen and (max-width: 1460px) {
    padding-right: 2rem;
  }

}

.seats{
  font-family: 'Inter',sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: #4F4F4F;
  flex-wrap: wrap;
  @media screen and (max-width: 1024px) {
    font-size:10px;
    line-height: 12px;
  }
}
</style>